import React from "react"
import styled from "styled-components"

// images
import Arrow from "../../../../../assets/images/jobfair-assets/arrow.svg"

// components
import Button from "../../../Button"
import { FormattedMessage } from "react-intl"

const CSS = styled.div`
  .dropdown {
    margin-right: 1.125rem !important;
    position: relative;
    outline: none;

    .head {
      display: flex;
      align-items: center;
      width: max-content;
      height: min-content;
      border: 1px solid #595959;
      box-sizing: border-box;
      border-radius: 0.5rem;
      padding: 0.75rem 1.25rem;
      cursor: pointer;

      &:hover {
        background: #2c2c2c !important;
      }

      &.active {
        background: #272727;
      }

      span.filter-count {
        margin-right: 0.6875rem;
      }

      h1 {
        font-size: 1.125rem;
        font-weight: bold;
        margin: 0;
      }

      img {
        margin-left: 0.875rem;
        width: 0.75rem;
      }
    }

    .content {
      visibility: hidden;
      background: #2c2c2c;
      border-radius: 0.5rem;
      padding: 1.5rem;
      position: absolute;
      box-sizing: border-box;
      width: 100%;
      height: min-content;
      z-index: 10;

      .buttons {
        display: flex;
        justify-content: flex-end;

        > div:first-child {
          button {
            background: none;
          }
        }

        button {
          font-family: DM Sans;
          font-style: normal;
          font-weight: bold;
          font-size: 1rem;
          line-height: 140%;
        }
      }
    }

    .content.show {
      visibility: visible;

      &.employmenttype {
        width: 26.9375rem;
      }

      &.company {
        width: 26.9375rem;
      }

      &.industry {
        width: 40.5625rem;
      }
    }
  }
`

const DropDown = ({
  name,
  children,
  idx,
  classname,
  clickAction,
  filterNum,
  showContent,
  setShowContent,
}) => {
  const show = idx === showContent
  return (
    <CSS>
      <div className="dropdown">
        <div
          className={`head ${filterNum > 0 ? "active" : ""}`}
          onClick={() => (show ? setShowContent(null) : setShowContent(idx))}
        >
          <DropDown.FilterCount filterCount={filterNum} />
          <h1>{name}</h1>
          <img src={Arrow} alt="" />
        </div>
        {show && (
          <div className={`content ${show ? "show" : ""} ${classname}`}>
            {children}
            <div className="buttons">
              <Button onClick={() => setShowContent(null)}>
                <FormattedMessage id="cancel" />
              </Button>
              <Button type="submit" onClick={(val) => clickAction(val)}>
                <FormattedMessage id="apply" />
              </Button>
            </div>
          </div>
        )}
      </div>
    </CSS>
  )
}

const FilterCountCSS = styled.div`
  span.filter-count {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ec006a;
    width: 1.125rem;
    height: 1.125rem;
    border-radius: 50%;
    box-sizing: border-box;
    margin-right: 0.6875rem;

    h5 {
      font-family: "DM Sans";
      font-size: 0.875rem;
      color: white;
    }
  }
`

DropDown.FilterCount = ({ filterCount }) =>
  filterCount > 0 && (
    <FilterCountCSS>
      <span className="filter-count">
        <h5>{filterCount}</h5>
      </span>
    </FilterCountCSS>
  )

export default DropDown
