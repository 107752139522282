import styled from "styled-components"
const SearchPageCSS = styled.div`
  .search-page.mobile {
    .search {
      padding: 1rem;
    }
  }

  .search-page {
    .desktop {
      display: block;
    }

    .mobile {
      display: none;
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
      margin: 0;
      font-family: "itc";
    }

    button {
      outline: none;
    }

    .search {
      background: #212121;
      padding: 3.5rem 7.125rem;
    }

    .form {
      background: none;

      button {
        font-family: "DM Sans";
        font-weight: bold;
      }
    }
  }

  .filter-toast-container {
    display: flex;
    justify-content: center;
    position: fixed;
    z-index: 15;
    bottom: 0;
    padding-bottom: 4rem;
    height: min-content;
    box-sizing: border-box;
    width: 100vw;

    .filter-toast {
      display: flex;
      align-items: center;
      background: #373737;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 1.625rem;
      padding: 0.875rem 1.4375rem;
      cursor: pointer;
      transform: translate(0, 20vh);
      transition: transform 0.2s linear;
      transition-delay: 0.1s;

      &.show {
        transform: translate(0, 0);
        transition: transform 0.2s linear;
        transition-delay: 0.1s;
      }

      img {
        width: 1.125rem;
      }

      h4 {
        color: #fdd262;
        font-family: DM Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 0.875rem;
        line-height: 140%;
        margin-left: 0.6875rem;
      }

      span.filter-count {
        margin-left: 0.6875rem;
        margin-right: 0;
      }
    }
  }

  @media (max-width: 1060px) {
    .search-page {
      .desktop {
        display: none;
      }
      .mobile {
        display: block;
      }
    }
  }
`

export default SearchPageCSS
