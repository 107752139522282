import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Formik } from "formik"

// styling
import SearchPageCSS from "./SearchPageCSS"

// images
import Filter from "../../../../assets/images/jobfair-assets/filter.svg"

// components
import Result from "./Result"
import MobileForm from "./MobileForm"
import DesktopForm from "./DesktopForm"
import DropDown from "./DropDown"

// redux
import { useSelector, useDispatch } from "react-redux"

// funcs
import { sortObjArr, getObjArr, getUniqueStringArr } from "../utils/funcs"
import {
  setShowContent,
  setSearchHistory,
  setKeyword,
  setLocation,
  setEmploymentType,
  setCompany,
  setIndustry,
  setShowMobileFilter,
  setShowDropDownContent,
  setCustomKeyword,
} from "../../../redux/slices/jobfair"

const initialState = {
  searchHistory: [],
  keyword: "",
  location: "",
  employmentType: [],
  company: [],
  industry: [],
}

const SearchPage = ({ data, isMobile }) => {
  const dispatch = useDispatch()

  return (
    <SearchPageCSS>
      <main className={`search-page ${isMobile ? "mobile" : ""}`}>
        <SearchPage.Search data={data} isMobile={isMobile} />
        <Result data={data} isMobile={isMobile} />
        {isMobile && (
          <div
            className="filter-toast-container"
            onClick={() => {
              dispatch(setShowMobileFilter(true))
            }}
          >
            <SearchPage.Toast />
          </div>
        )}
      </main>
    </SearchPageCSS>
  )
}

SearchPage.Toast = () => {
  const [scroll, setScroll] = useState(
    typeof window !== "undefined" && window.scrollY
  )
  const [showToast, setShowToast] = useState(true)

  useEffect(() => {
    window.addEventListener("scroll", (e) => handleScroll(e))
  })

  const handleScroll = (e) => {
    const window = e.currentTarget

    if (scroll > window.scrollY) {
      // up (show)
      setShowToast(true)
    } else if (scroll < window.scrollY) {
      // down (hide)
      setShowToast(false)
    }
    setScroll(window.scrollY)
  }

  const { location, employmentType, company, industry } = useSelector(
    (state) => state.jobfair
  )

  const mobileFilterCount =
    employmentType.length +
    company.length +
    industry.length +
    (location === "" ? 0 : 1)

  return (
    <div className={`filter-toast ${showToast ? "show" : ""}`}>
      <img src={Filter} alt="" />
      <h4>Filter</h4>
      <DropDown.FilterCount filterCount={mobileFilterCount} />
    </div>
  )
}

SearchPage.Search = ({ data, isMobile }) => {
  const { companyNames1, industryList1 } = useStaticQuery(
    graphql`
      query {
        companyNames1: allContentfulPerusahaan {
          distinct(field: nama)
        }

        industryList1: allContentfulPerusahaan {
          distinct(field: kategori)
        }
      }
    `
  )

  const dispatch = useDispatch()
  const {
    searchHistory,
    location,
    employmentType,
    company,
    industry,
  } = useSelector((state) => state.jobfair)

  const { locationList, employmentTypeList } = data.allContentfulJobOpening

  const industryList = industryList1.distinct

  const companyNames = companyNames1.distinct

  const employmentTypeObjArr = sortObjArr(
    getObjArr(
      getUniqueStringArr(employmentTypeList),
      data.allContentfulJobOpening.nodes,
      "employmentType"
    ),
    "sum"
  ).reverse()

  const industryObjArr = sortObjArr(
    getObjArr(
      getUniqueStringArr(industryList),
      data.allContentfulJobOpening.nodes,
      "perusahaan",
      "kategori"
    ),
    "sum"
  ).reverse()

  const companyObjArr = sortObjArr(
    getObjArr(
      getUniqueStringArr(companyNames),
      data.allContentfulJobOpening.nodes,
      "perusahaan",
      "nama"
    ),
    "sum"
  ).reverse()

  const locationObjArr = sortObjArr(
    getObjArr(
      getUniqueStringArr(locationList),
      data.allContentfulJobOpening.nodes,
      "lokasi"
    ),
    "sum"
  ).reverse()

  const [tempEmploymentType, setTempEmploymentType] = useState(employmentType)
  const [tempIndustry, setTempIndustry] = useState(industry)
  const [tempCompany, setTempCompany] = useState(company)

  const resetVal = (setVal, values) => {
    Object.keys(values).map((key) => setVal(key, initialState[key]))
  }

  const uniqueArr = (arr, item) => {
    let temp = [...arr]
    const idx = temp.indexOf(item)
    temp = idx === -1 ? temp.concat(item) : temp
    return temp
  }

  const changeUrlParam = (kw) => {
    history.pushState({ page: 1 }, "title 1", `?search=${kw}`)
    history.go(1)
  }

  const isFiltered =
    location !== "" ||
    employmentType.length > 0 ||
    company.length > 0 ||
    industry.length > 0

  return (
    <div className={`search ${isMobile ? "mobile" : ""}`}>
      <div className="form">
        <Formik
          initialValues={{
            keyword: "",
            location: location,
            company: company,
            industry: industry,
            companySearch: "",
            employmentType: tempEmploymentType,
          }}
          onSubmit={(values) => {
            const {
              keyword,
              location,
              employmentType,
              industry,
              company,
            } = values
            keyword !== "" &&
              dispatch(setSearchHistory(uniqueArr(searchHistory, keyword)))
            dispatch(setKeyword(keyword))
            dispatch(setLocation(location))
            dispatch(setEmploymentType(employmentType))
            dispatch(setIndustry(industry))
            dispatch(setCompany(company))
            dispatch(setShowContent(null))
            dispatch(setShowDropDownContent(null))
            dispatch(setCustomKeyword(null))
            changeUrlParam(keyword)
          }}
        >
          {({ isSubmitting, values, setFieldValue, submitForm }) => (
            <>
              <div className="desktop">
                <DesktopForm
                  data={data}
                  values={values}
                  isSubmitting={isSubmitting}
                  setFieldValue={setFieldValue}
                  tempEmploymentType={tempEmploymentType}
                  setTempEmploymentType={setTempEmploymentType}
                  tempIndustry={tempIndustry}
                  setTempIndustry={setTempIndustry}
                  tempCompany={tempCompany}
                  setTempCompany={setTempCompany}
                  isFiltered={isFiltered}
                  submitForm={submitForm}
                  employmentTypeObjArr={employmentTypeObjArr}
                  industryObjArr={industryObjArr}
                  companyObjArr={companyObjArr}
                  industryList={industryList}
                  companyNames={companyNames}
                  resetVal={() => {
                    const { employmentType, industry, company } = initialState
                    setTempEmploymentType(employmentType)
                    setTempIndustry(industry)
                    setTempCompany(company)
                    resetVal(setFieldValue, values)
                  }}
                />
              </div>
              <div className="mobile">
                <MobileForm
                  data={data}
                  values={values}
                  isSubmitting={isSubmitting}
                  setFieldValue={setFieldValue}
                  tempEmploymentType={tempEmploymentType}
                  setTempEmploymentType={setTempEmploymentType}
                  tempIndustry={tempIndustry}
                  setTempIndustry={setTempIndustry}
                  tempCompany={tempCompany}
                  setTempCompany={setTempCompany}
                  isFiltered={isFiltered}
                  submitForm={submitForm}
                  industryList={industryList}
                  companyNames={companyNames}
                />
                <MobileForm.FilterPageContainer
                  data={data}
                  values={values}
                  isSubmitting={isSubmitting}
                  setFieldValue={setFieldValue}
                  tempEmploymentType={tempEmploymentType}
                  setTempEmploymentType={setTempEmploymentType}
                  tempIndustry={tempIndustry}
                  setTempIndustry={setTempIndustry}
                  tempCompany={tempCompany}
                  setTempCompany={setTempCompany}
                  isFiltered={isFiltered}
                  submitForm={submitForm}
                  resetVal={() => resetVal(setFieldValue, values)}
                  employmentTypeObjArr={employmentTypeObjArr}
                  industryObjArr={industryObjArr}
                  locationObjArr={locationObjArr}
                  companyObjArr={companyObjArr}
                />
              </div>
            </>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default SearchPage
