import React, { useState } from "react"
import styled from "styled-components"
import { Field } from "formik"

const CSS = styled.div`
  width: 100%;

  &:first-child {
    margin-right: 1.125rem;
  }

  &:nth-child(2) {
    margin-right: 1.5rem;
  }

  .wide-input {
    font-size: 1.125rem;
    background: #323232;
    border-radius: 0.5rem;
    padding: 0;
    /*width: 33.375rem;*/
    width: 100%;
    min-width: 340px;
    position: relative;
    outline: none;

    .input {
      display: flex;
    }

    .icon-container {
      padding: 1rem 0;
      display: flex;
      align-items: center;

      img {
        height: 1.093125rem;
        margin: 0 1em;
      }
    }

    input {
      border: none;
      background: none;
      width: 100%;
      outline: none;
      padding: 0;

      &::placeholder {
        opacity: 0.6;
      }
      &::placeholder,
      & {
        color: #f3f3f3;
        font-size: 1.125rem;
        font-weight: 500;
      }
    }

    .content {
      visibility: hidden;
      background: #2c2c2c;
      border-radius: 0.5rem;
      padding: 1.5rem 0;
      position: absolute;
      box-sizing: border-box;
      width: 100%;
      height: min-content;
      z-index: 11;

      & * {
        visibility: hidden;
      }
    }

    .content.show {
      visibility: visible;

      & * {
        visibility: visible;
      }
    }
  }
`

const WideInput = ({
  icon,
  name,
  placeholder,
  children,
  classname,
  clickAction,
}) => {
  const [show, setShow] = useState(false)

  return (
    <CSS>
      <div
        className={`wide-input ${classname}`}
        onFocus={() => setShow(true)}
        onBlur={() => setShow(false)}
        tabIndex={100}
        onClick={clickAction}
      >
        <div className="input">
          <div className="icon-container">
            <img src={icon} alt="" />
          </div>
          <Field
            type="text"
            name={name}
            placeholder={placeholder}
            autoComplete="off"
          />
        </div>
        <div className={`content ${show && children !== null ? "show" : ""}`}>
          {children}
        </div>
      </div>
    </CSS>
  )
}

export default WideInput
