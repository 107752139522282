import React, { useEffect, useState } from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { graphql, useStaticQuery } from "gatsby"

// components
import SearchPage from "../../components/JobFair/SearchPage"

// redux
import { useDispatch } from "react-redux"
import { setKeyword } from "../../redux/slices/jobfair"

const BREAKPOINT = 1060

const JobFairSearchPage = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allContentfulJobOpening(sort: { order: ASC, fields: createdAt }) {
          nodes {
            nama
            backendId
            slug
            lokasi
            perusahaan {
              logo {
                fluid {
                  src
                }
              }
              nama
              kategori
            }
            employmentType
            slug
            jobCategory {
              kategori
            }
            tags {
              tags
            }
          }
          companyNames: distinct(field: perusahaan___nama)
          locationList: distinct(field: lokasi)
          jobList: distinct(field: nama)
          employmentTypeList: distinct(field: employmentType)
          industryList: distinct(field: perusahaan___kategori)
        }
      }
    `
  )

  const dispatch = useDispatch()
  const urlParams = new URLSearchParams(
    typeof window !== "undefined" && window.location.search
  )
  const checkSearchPage = () => {
    return urlParams.get("search") !== null
  }

  const [windowWidth, setWindowWidth] = useState(0)
  const isMobile = windowWidth < BREAKPOINT

  useEffect(() => {
    checkSearchPage() && dispatch(setKeyword(urlParams.get("search")))
    setWindowWidth(window.innerWidth)

    const onResize = () => {
      setWindowWidth(window.innerWidth)
    }
    window.addEventListener("resize", onResize)
    return () => window.removeEventListener("resize", onResize)
  }, [])

  return (
    <Layout>
      <SEO title="Job Fair" />
      <SearchPage data={data} isMobile={isMobile} />
    </Layout>
  )
}

export default JobFairSearchPage
