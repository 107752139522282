import React, { useState, useEffect } from "react"
import styled from "styled-components"

// images
import CheckMark from "../../../../../assets/images/jobfair-assets/checkmark.svg"

const CSS = styled.div`
  margin-bottom: 0.75rem !important;

  &:last-child,
  &:last-child .checklist-item {
    margin: 0 !important;
  }

  .checklist-item {
    display: flex;
    align-items: center;
    cursor: pointer;

    .checkbox {
      width: 0.875rem;
      height: 0.875rem;
      border: 2px solid #f3f3f3;
      border-radius: 0.2rem;
      margin-right: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        visibility: hidden;
        width: 0.75rem;
      }

      &.radio {
        width: 0.625rem;
        height: 0.625rem;
        background: black;
        border-radius: 50%;
        border: 3px solid black;
        box-shadow: 0 0 0 2px #f3f3f3;
      }
    }

    h5 {
      font-weight: normal;
      font-size: 1.125rem;
      vertical-align: middle;
    }
  }

  .checklist-item.checked {
    .checkbox {
      background: #f3f3f3;

      img {
        visibility: visible;
      }

      &.radio {
        background: #f3f3f3;
        img {
          display: none;
        }
      }
    }
  }
`

const CheckItem = ({
  item,
  clickAction,
  val,
  singleVal,
  current,
  obj,
  displayFormat,
  itemVal,
}) => {
  const checkedDefault = singleVal
    ? current === itemVal
    : current && current.indexOf(itemVal) !== -1
  const [checked, setChecked] = useState(checkedDefault)

  useEffect(() => {
    setChecked(checkedDefault)
  }, [current, itemVal])

  useEffect(() => {
    singleVal && setChecked(val === itemVal)
  }, [val])

  return (
    <CSS>
      <div
        className={`checklist-item ${checked ? "checked" : ""}`}
        onClick={() => {
          !singleVal && setChecked((prev) => !prev)
          clickAction()
        }}
      >
        <div className={`checkbox ${singleVal ? "radio" : ""}`}>
          <img src={CheckMark} alt="" />
        </div>
        <h5>{obj ? displayFormat(item) : item}</h5>
      </div>
    </CSS>
  )
}

export default CheckItem
