import React from "react"
import styled from "styled-components"
import { FormattedMessage } from "react-intl"

// redux
import { useSelector } from "react-redux"

// components
import JobItem from "../../JobItem"
import NoResultPage from "../NoResultPage"

// utils
import { cleanString, findInArr, fuzzySearchStr } from "../../utils/funcs"
import { useStaticQuery, graphql } from "gatsby"

const CSS = styled.div`
  .result.mobile {
    padding: 1rem;
  }

  .result {
    padding: 1.5rem 7.125rem;
    height: max-content;
    box-sizing: border-box;

    h1.result-info {
      font-size: 1rem;
      font-weight: normal;
      height: 1.375rem;
      margin-bottom: 1rem;
      height: max-content;
      span {
        font-weight: bold;
      }
    }
  }

  .header {
    display: flex;
    justify-content: space-between;

    h2 {
      font-size: 0.875rem;
      font-weight: normal;
      height: 1.25rem;
    }
  }

  .img-container {
    height: 4.375rem;
    width: 4.375rem;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border-radius: 0.5rem;

    img {
      height: 3.5rem;
    }
  }
`

const Result = ({ data, isMobile }) => {
  const jobData = data.allContentfulJobOpening.nodes
  const { jobsByCategory } = useStaticQuery(graphql`
    query {
      jobsByCategory: allContentfulJobCategory {
        nodes {
          kategori
          job_opening {
            nama
            backendId
            slug
            lokasi
            perusahaan {
              logo {
                fluid {
                  src
                }
              }
              nama
              kategori
            }
            employmentType
            slug
            jobCategory {
              kategori
            }
            tags {
              tags
            }
          }
        }
      }
    }
  `)

  const {
    keyword,
    location,
    employmentType,
    company,
    industry,
    customKeyword,
  } = useSelector((state) => state.jobfair)

  const filterArr = (job) => {
    const curCompanyName = job.perusahaan ? job.perusahaan.nama.trim() : ""
    const curJobTitle = job.nama.trim()
    const curEmploymentType = job.employmentType.trim()
    const curLocation = job.lokasi.trim()
    const curIndustry = job.perusahaan ? job.perusahaan.kategori.trim() : ""
    const curTags = job.tags ? job.tags.tags.trim() : ""

    // matches
    const matchCompany =
      company.length > 0 ? company.includes(curCompanyName) : true
    const matchEmploymentType =
      employmentType.length > 0
        ? employmentType.includes(curEmploymentType)
        : true
    const matchIndustry =
      industry.length > 0 ? industry.includes(curIndustry) : true
    const matchLocation =
      location.length > 0 ? fuzzySearchStr(curLocation, location) : true

    const cleanKeyword = cleanString(keyword)
    const match = (str) => {
      return keyword.includes(",")
        ? findInArr(str, keyword.split(","))
        : fuzzySearchStr(cleanKeyword, str)
    }

    const matchString =
      match(curCompanyName) || match(curJobTitle) || match(curTags)

    const res =
      matchCompany &&
      matchEmploymentType &&
      matchIndustry &&
      matchLocation &&
      matchString
    return res
  }

  const getCategoryJobs = () => {
    for (let i = 0; jobsByCategory.nodes.length; i++) {
      if (jobsByCategory.nodes[i].kategori === customKeyword) {
        return jobsByCategory.nodes[i].job_opening
      }
    }
  }

  return (
    <CSS>
      <div className={`result ${isMobile ? "mobile" : ""}`}>
        {keyword && (
          <h1 className="result-info">
            {location ? (
              <FormattedMessage
                id="jobfair.search.showingIn"
                values={{
                  search: customKeyword || keyword,
                  location,
                  span: (chunks) => <span>{chunks}</span>,
                }}
              />
            ) : (
              <FormattedMessage
                id="jobfair.search.showing"
                values={{
                  search: customKeyword || keyword,
                  span: (chunks) => <span>{chunks}</span>,
                }}
              />
            )}
          </h1>
        )}
        <div className="job-list">
          {jobData.filter(filterArr).length === 0 ? (
            <NoResultPage isMobile={isMobile} />
          ) : (
            (customKeyword
              ? getCategoryJobs()
              : jobData.filter(filterArr)
            ).map((job, idx) => (
              <JobItem
                companyName={job.perusahaan ? job.perusahaan.nama : ""}
                icon={
                  job.perusahaan
                    ? job.perusahaan.logo.fluid
                      ? job.perusahaan.logo.fluid.src
                      : ""
                    : ""
                }
                jobTitle={job.nama}
                employmentType={job.employmentType}
                location={job.lokasi}
                category={job.jobCategory ? job.jobCategory[0].kategori : ""}
                key={idx}
                isMobile={isMobile}
                slug={job.slug}
                id={job.backendId}
              />
            ))
          )}
        </div>
      </div>
    </CSS>
  )
}

export default Result
