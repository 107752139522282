import React from "react"
import styled from "styled-components"
import { Form } from "formik"
import { useSelector, useDispatch } from "react-redux"
import { useIntl, FormattedMessage } from "react-intl"

// images
import SearchIcon from "../../../../../assets/images/jobfair-assets/search.svg"
import LocationIcon from "../../../../../assets/images/jobfair-assets/location.svg"
import Clock from "../../../../../assets/images/jobfair-assets/clock.svg"
import Cross from "../../../../../assets/images/jobfair-assets/cross.svg"

// components
import Button from "../../../Button"
import CheckList from "../CheckList"
import WideInput from "../WideInput"
import DropDown from "../DropDown"

// funcs
import { filterArr, filterObjArr, sortObjArr } from "../../utils/funcs"

import {
  clearSearchHistory,
  clearFilter,
  setShowDropDownContent,
} from "../../../../redux/slices/jobfair"

const CSS = styled.div`
  form {
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .wide-input.search-input {
        .search-dropdown {
          .search-list-top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: min-content;
            margin-bottom: 1rem;
            padding: 0 1.5rem;

            div {
              display: flex;
              img {
                height: 1.25rem;
                margin-right: 0.625rem;
              }

              h4 {
                font-size: 1rem;
                line-height: 140%;
              }
            }

            span {
              background: none;
              border-radius: 0.25rem;
              padding: 0.5rem 0.75rem;
              display: block;
              cursor: pointer;

              &:hover {
                background: #353535;
              }

              h3 {
                font-size: 0.875rem;
                height: min-content;
                font-weight: normal;
                color: #fdd262;
              }
            }
          }
        }
      }
    }

    .bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 2rem;

      .clear-filter,
      .filters {
        display: flex;
      }

      .clear-filter {
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background: none;
        padding: 0.75em 1.25em;
        border-radius: 1.625rem;

        &:hover {
          background: #2c2c2c;
        }

        img {
          height: 0.875rem;
          margin-right: 0.875rem;
        }

        h5 {
          font-family: "DM Sans";
          font-size: 1.125rem;
          font-weight: 500;
          color: #f3f3f3;
        }
      }
    }

    div.dropdown-item {
      display: flex;
      align-items: center;
      height: 1.375rem;
      /*margin-bottom: 1.25rem;*/
      cursor: pointer;
      padding: 0 1.5rem;
      height: 2.625rem;

      h2 {
        font-family: DM Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 1rem;
        line-height: 140%;
      }

      &:hover {
        background: #353535;
      }
    }

    .wide-input.location-input {
      .location-list {
        width: 100%;
      }
    }

    .company-content {
      .wide-input.company-search {
        width: 100%;

        .content {
          display: none;
        }
      }

      h4 {
        font-size: 1.125rem;
        font-weight: 500;
        margin: 1.25rem 0;
        height: 1.5625rem;
      }

      .checklist-container {
        .checklist-item {
          margin-bottom: 1.5625rem;
        }
      }
    }
  }

  .industry-checklist {
    height: 15rem;
    overflow-y: scroll;
  }
`

const DesktopForm = ({
  data,
  values,
  isSubmitting,
  setFieldValue,
  tempEmploymentType,
  setTempEmploymentType,
  tempIndustry,
  setTempIndustry,
  tempCompany,
  setTempCompany,
  isFiltered,
  resetVal,
  employmentTypeObjArr,
  industryObjArr,
  companyObjArr,
  industryList,
  companyNames,
}) => {
  const { locationList, jobList } = data.allContentfulJobOpening

  const {
    searchHistory,
    employmentType,
    company,
    industry,
    showDropDownContent,
  } = useSelector((state) => state.jobfair)

  const dispatch = useDispatch()
  const intl = useIntl()

  return (
    <CSS>
      <Form>
        <div className="top">
          <WideInput
            classname="search-input"
            icon={SearchIcon}
            name="keyword"
            placeholder={intl.formatMessage({
              id: "jobfair.index.searchPlaceholder",
            })}
          >
            {searchHistory.length > 0 && values.keyword === "" ? (
              <div className="search-dropdown">
                <div className="search-list-top">
                  <div>
                    <img src={Clock} alt="" />
                    <h4>
                      <FormattedMessage id="jobfair.search.recentSearches" />
                    </h4>
                  </div>
                  <span onClick={() => dispatch(clearSearchHistory())}>
                    <h3>
                      <FormattedMessage id="jobfair.search.clearHistory" />
                    </h3>
                  </span>
                </div>
                <div className="search-list-bottom">
                  {[...searchHistory]
                    .reverse()
                    .slice(0, 4)
                    .map((item, idx) => (
                      <DesktopForm.DropDownItem
                        item={item}
                        key={idx}
                        clickAction={() => setFieldValue("keyword", item)}
                      />
                    ))}
                </div>
              </div>
            ) : filterArr(jobList.concat(companyNames), values.keyword).length >
              0 ? (
              <div className="search-dropdown">
                <div className="search-list-bottom">
                  {filterArr(jobList.concat(companyNames), values.keyword)
                    .slice(0, 5)
                    .map((item, idx) => (
                      <DesktopForm.DropDownItem
                        item={item}
                        key={idx}
                        clickAction={() => setFieldValue("keyword", item)}
                      />
                    ))}
                </div>
              </div>
            ) : null}
          </WideInput>
          <WideInput
            classname="location-input"
            icon={LocationIcon}
            name="location"
            placeholder={intl.formatMessage({
              id: "jobfair.search.location",
            })}
          >
            {filterArr(locationList, values.location).length > 0 ? (
              <div className="location-list">
                {filterArr(locationList, values.location)
                  .slice(0, 5)
                  .map((item, idx) => (
                    <DesktopForm.DropDownItem
                      item={item}
                      key={idx}
                      clickAction={() => setFieldValue("location", item)}
                    />
                  ))}
              </div>
            ) : null}
          </WideInput>
          <Button type="submit">
            <FormattedMessage id="jobfair.searchButton" />
          </Button>
        </div>
        <div className="bottom">
          <div className="filters">
            <DropDown
              idx={2}
              name={intl.formatMessage({
                id: "jobfair.search.employmentType",
              })}
              classname="employmenttype"
              clickAction={() =>
                setFieldValue("employmentType", [...tempEmploymentType])
              }
              filterNum={employmentType.length}
              showContent={showDropDownContent}
              setShowContent={(val) => dispatch(setShowDropDownContent(val))}
            >
              <CheckList
                data={employmentTypeObjArr}
                val={tempEmploymentType}
                setVal={setTempEmploymentType}
                current={employmentType}
                displayFormat={(obj) => `${obj.attr} (${obj.sum})`}
                submitAttr="attr"
                obj
              />
            </DropDown>
            <DropDown
              idx={3}
              name={intl.formatMessage({
                id: "jobfair.search.company",
              })}
              classname="company"
              clickAction={() => setFieldValue("company", [...tempCompany])}
              filterNum={company.length}
              showContent={showDropDownContent}
              setShowContent={(val) => dispatch(setShowDropDownContent(val))}
            >
              <div className="company-content">
                <WideInput
                  classname="company-search"
                  icon={SearchIcon}
                  name="companySearch"
                  placeholder={intl.formatMessage({
                    id: "jobfair.search.company.placeholder",
                  })}
                />
                <h4>{`${
                  values.companySearch !== "" ? "Search results" : ""
                }`}</h4>
                <CheckList
                  data={sortObjArr(
                    filterObjArr(
                      companyObjArr,
                      "attr",
                      values.companySearch,
                      true
                    ),
                    "sum"
                  ).reverse()}
                  dataLimit={5}
                  val={tempCompany}
                  setVal={setTempCompany}
                  current={tempCompany}
                  displayFormat={(obj) => `${obj.attr} (${obj.sum})`}
                  submitAttr="attr"
                  obj
                />
              </div>
            </DropDown>
            <DropDown
              idx={4}
              name={intl.formatMessage({
                id: "jobfair.search.industry",
              })}
              classname="industry"
              clickAction={() => setFieldValue("industry", [...tempIndustry])}
              filterNum={industry.length}
              showContent={showDropDownContent}
              setShowContent={(val) => dispatch(setShowDropDownContent(val))}
            >
              <div className="industry-checklist">
                <CheckList
                  data={industryObjArr}
                  val={tempIndustry}
                  setVal={setTempIndustry}
                  current={industry}
                  displayFormat={(obj) => `${obj.attr} (${obj.sum})`}
                  submitAttr="attr"
                  obj
                />
              </div>
            </DropDown>
          </div>
          {isFiltered && (
            <div
              className="clear-filter"
              onClick={() => {
                dispatch(clearFilter())
                resetVal()
              }}
            >
              <img src={Cross} alt="" />
              <h5>Clear Filters</h5>
            </div>
          )}
        </div>
      </Form>
    </CSS>
  )
}

DesktopForm.DropDownItem = ({ item, clickAction }) => (
  <div className="dropdown-item" onClick={clickAction}>
    <h2>{item.length > 50 ? `${item.substr(0, 45)}...` : item}</h2>
  </div>
)

export default DesktopForm
