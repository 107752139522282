import React from "react"
// import styled from "styled-components"

// components
import CheckItem from "../CheckItem"

const CheckList = ({
  data,
  val,
  setVal,
  current,
  dataLimit,
  singleVal,
  obj,
  displayFormat,
  submitAttr,
}) => {
  return (
    <div className="checklist-container">
      {data.slice(0, dataLimit || data.length).map((item, idx) => (
        <CheckItem
          item={item}
          itemVal={obj ? item[submitAttr] : item}
          key={idx}
          val={val}
          current={current}
          singleVal={!!singleVal}
          clickAction={() => {
            item = obj ? item[submitAttr] : item
            let temp = [...val]
            if (temp.indexOf(item) !== -1) {
              temp.splice(temp.indexOf(item), 1)
            } else {
              temp = temp.concat(item)
            }
            setVal(singleVal ? (val === item ? "" : item) : [...temp])
          }}
          obj={!!obj}
          displayFormat={displayFormat}
        />
      ))}
    </div>
  )
}

export default CheckList
