import React from "react"
import styled from "styled-components"
import { FormattedMessage } from "react-intl"

// images
import Mascot from "../../../../../assets/images/jobfair-assets/mascot.svg"

const CSS = styled.div`
  main.no-result-page {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 8.625rem 0;
    box-sizing: border-box;

    img {
      height: 12.830625rem;
      margin-bottom: 1.544375rem;
    }

    h1,
    small {
      color: #f3f3f3;
      line-height: 140%;
      font-style: normal;
      text-align: center;
    }

    h1 {
      font-family: "itc";
      font-weight: bold;
      font-size: 1.5rem;
      margin-bottom: 0.75rem;
    }

    small {
      font-family: "DM Sans";
      font-weight: normal;
      font-size: 1rem;
      opacity: 0.9;
    }
  }
`

const NoResultPage = ({ isMobile }) => {
  return (
    <CSS>
      <main className="no-result-page">
        <img src={Mascot} alt="" />
        <h1>
          <FormattedMessage id="jobfair.search.noMatchingJobs" />
        </h1>
        <small>
          <FormattedMessage id="jobfair.search.noMatchingJobs.tryAnother" />
        </small>
      </main>
    </CSS>
  )
}

export default NoResultPage
