import React, { useState } from "react"
import styled from "styled-components"
import { Form, Field } from "formik"
import { useIntl, FormattedMessage } from "react-intl"

// components
import WideInput from "../WideInput"
import DesktopForm from "../DesktopForm"
import Button from "../../../Button"
import CheckList from "../CheckList"

// images
import SearchIcon from "../../../../../assets/images/jobfair-assets/search.svg"
import LocationIcon from "../../../../../assets/images/jobfair-assets/location.svg"
import Clock from "../../../../../assets/images/jobfair-assets/clock.svg"
import CrossRound from "../../../../../assets/images/jobfair-assets/cross-round.svg"
import BackArrow from "../../../../../assets/images/jobfair-assets/back-arrow.svg"

// redux
import { useSelector, useDispatch } from "react-redux"

// funcs
import { filterArr } from "../../utils/funcs"

import {
  setShowMobileSearch,
  setShowMobileFilter,
  clearSearchHistory,
  clearFilter,
} from "../../../../redux/slices/jobfair"

const CSS = styled.div`
  .wide-input.search-input {
    width: 100%;
    margin-right: 0 !important;
    min-width: 200px;

    & * {
      cursor: pointer;
    }

    .content {
      display: none;
    }
  }

  form {
    background: black;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 30;

    .search-form {
      display: flex;
      background: #1d1d1d;
      height: min-content;

      &:first-child {
        input {
          border-bottom: 1px solid #373737;
          box-sizing: border-box;
        }
      }

      .img-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 3.5rem;

        img {
          height: 1.093125rem;
          width: 1.093125rem;
          opacity: 0.6;
        }
      }

      input {
        height: 3rem;
        width: 100%;
        background: none;
        outline: none;
        border: none;
        padding: 0;

        &::placeholder,
        & {
          font-family: DM Sans;
          font-style: normal;
          font-weight: 500;
          font-size: 0.875;
          line-height: 140%;
          color: #f3f3f3;
          opacity: 0.6;
        }
      }
    }

    .search-dropdown {
      .search-list-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: min-content;
        margin-bottom: 0.625rem;
        padding: 1.375rem 1.125rem 0 1.125rem;

        div {
          display: flex;
          img {
            height: 1.25rem;
            margin-right: 0.625rem;
          }

          h4 {
            font-size: 1rem;
            line-height: 140%;
          }
        }

        span {
          display: block;
          cursor: pointer;

          h3 {
            font-size: 0.875rem;
            height: 1.25rem;
            font-weight: normal;
            color: #fdd262;
          }
        }
      }
    }

    div.dropdown-item {
      display: flex;
      align-items: center;
      height: 1.375rem;
      cursor: pointer;
      padding: 0 1rem;
      height: 3.375rem;
      border-bottom: 1px solid #373737;

      h2 {
        font-family: DM Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 1rem;
        line-height: 140%;
      }

      &:hover {
        background: #353535;
      }
    }
  }

  .search-submit {
    position: absolute;
    bottom: 4rem;
    width: 100vw;
    padding: 1rem;
    box-sizing: border-box;

    > div {
      width: 100%;
    }

    button {
      width: 100%;
      font-weight: normal !important;
    }
  }

  .mobile-filter {
    .header {
      font-family: "DM Sans";
      width: 100vw;
      display: flex;
      align-items: center;
      background: #1d1d1d;
      padding: 1.125rem 1rem 1rem 1rem;
      box-sizing: border-box;

      img {
        margin-right: auto;
        height: 0.82375rem;
      }

      h1,
      h2 {
        height: 1.375rem;
        font-style: normal;
        font-size: 1rem;
        line-height: 140%;
      }

      h1 {
        margin: 0 auto;
        color: #f3f3f3;
        font-weight: 500;
      }

      h2 {
        margin-left: auto;
        font-weight: normal;
        background: -webkit-linear-gradient(135deg, #d418b6 0%, #f1185e 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }

  form.mobile-form-page-container {
    top: 100%;
    transition: top 0.2s;
  }

  form.mobile-form-page-container.show {
    top: 0;
    transition: top 0.2s;
  }
`

const MobileForm = ({
  data,
  values,
  isSubmitting,
  setFieldValue,
  tempEmploymentType,
  setTempEmploymentType,
  tempIndustry,
  setTempIndustry,
  tempCompany,
  setTempCompany,
  isFiltered,
  submitForm,
  industryList,
  companyNames,
}) => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const [showInputContent, setShowInputContent] = useState(null)

  const { locationList, jobList } = data.allContentfulJobOpening

  const { showMobileSearch, searchHistory } = useSelector(
    (state) => state.jobfair
  )

  return (
    <CSS>
      {!showMobileSearch ? (
        <WideInput
          classname="search-input"
          icon={SearchIcon}
          name="keyword"
          placeholder={intl.formatMessage({
            id: "jobfair.index.searchPlaceholder",
          })}
          clickAction={() => dispatch(setShowMobileSearch(true))}
        />
      ) : (
        <Form>
          <>
            <MobileForm.Search
              name="keyword"
              placeholder={intl.formatMessage({
                id: "jobfair.search.searchByJobTitle",
              })}
              icon={BackArrow}
              focusAction={() => setShowInputContent(0)}
              clickAction={() => dispatch(setShowMobileSearch(false))}
            />
            <MobileForm.Search
              name="location"
              placeholder={intl.formatMessage({
                id: "jobfair.search.location",
              })}
              icon={LocationIcon}
              focusAction={() => setShowInputContent(1)}
            />
            <div className="search-content">
              {showInputContent === 0 ? (
                searchHistory.length > 0 && values.keyword === "" ? (
                  <div className="search-dropdown">
                    <div className="search-list-top">
                      <div>
                        <img src={Clock} alt="" />
                        <h4>
                          <FormattedMessage id="jobfair.search.recentSearches" />
                        </h4>
                      </div>
                      <span onClick={() => dispatch(clearSearchHistory())}>
                        <h3>
                          <FormattedMessage id="jobfair.search.clearHistory" />
                        </h3>
                      </span>
                    </div>
                    <div className="search-list-bottom">
                      {[...searchHistory]
                        .reverse()
                        .slice(0, 4)
                        .map((item, idx) => (
                          <DesktopForm.DropDownItem
                            item={item}
                            key={idx}
                            clickAction={() => setFieldValue("keyword", item)}
                          />
                        ))}
                    </div>
                  </div>
                ) : filterArr(jobList.concat(companyNames), values.keyword)
                    .length > 0 ? (
                  <div className="search-dropdown">
                    <div className="search-list-bottom">
                      {filterArr(jobList.concat(companyNames), values.keyword)
                        .slice(0, 5)
                        .map((item, idx) => (
                          <DesktopForm.DropDownItem
                            item={item}
                            key={idx}
                            clickAction={() => setFieldValue("keyword", item)}
                          />
                        ))}
                    </div>
                  </div>
                ) : null
              ) : (
                showInputContent === 1 &&
                (filterArr(locationList, values.location).length > 0 ? (
                  <div className="location-list">
                    {filterArr(locationList, values.location)
                      .slice(0, 5)
                      .map((item, idx) => (
                        <DesktopForm.DropDownItem
                          item={item}
                          key={idx}
                          clickAction={() => setFieldValue("location", item)}
                        />
                      ))}
                  </div>
                ) : null)
              )}
            </div>

            <div className="search-submit" onClick={submitForm}>
              {(values.keyword !== "" || values.location !== "") && (
                <Button onClick={() => dispatch(setShowMobileSearch(false))}>
                  <FormattedMessage id="jobfair.searchButton" />
                </Button>
              )}
            </div>
          </>
        </Form>
      )}
    </CSS>
  )
}

MobileForm.Search = ({ icon, placeholder, name, focusAction, clickAction }) => (
  <div className="search-form">
    <div className="img-container" onClick={clickAction}>
      <img src={icon} alt="" />
    </div>
    <Field
      type="text"
      name={name}
      placeholder={placeholder}
      onFocus={focusAction}
      autoComplete="off"
    />
  </div>
)

const FilterContainerCSS = styled.div`
  .filter-container {
    width: 100vw;
    padding: 1rem;
    padding-bottom: 0;
    box-sizing: border-box;

    .top {
      display: flex;
      justify-content: space-between;

      h1,
      h2 {
        height: 1.375rem;
      }

      h1 {
        font-family: "itc";
        font-style: normal;
        font-weight: 500;
        font-size: 1rem;
        line-height: 140%;
        color: #f3f3f3;
      }

      h2 {
        font-family: "DM Sans";
        font-style: normal;
        font-weight: bold;
        font-size: 0.875rem;
        line-height: 140%;
        background: -webkit-linear-gradient(135deg, #d418b6 0%, #f1185e 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .checklist-container {
      margin-top: 1rem;
      padding-bottom: 0.75rem;
      display: flex;
      border-bottom: 1px solid #373737;

      overflow-x: scroll;
      ::-webkit-scrollbar {
        display: none;
      }

      .checklist-item {
        border: 1px solid #949494;
        box-sizing: border-box;
        border-radius: 0.5rem;
        padding: 0.5rem 1rem;
        margin-right: 0.5rem;
        white-space: nowrap;

        &.checked {
          background: rgba(236, 0, 106, 0.2);
          border-color: #ec006a;
          /*border-color: linear-gradient(135deg, #D418B6 0%, #F1185E 100%);*/
        }

        .checkbox {
          display: none;
        }

        h5 {
          font-size: 0.875rem;
          font-family: "DM Sans";
        }
      }
    }
  }
`

MobileForm.Filter = ({
  title,
  seeAllAction,
  data,
  val,
  setVal,
  current,
  singleVal,
  displayFormat,
  submitAttr,
  obj,
}) => {
  return (
    <FilterContainerCSS>
      <div className="filter-container">
        <div className="top">
          <h1>{title}</h1>
          <h2 onClick={seeAllAction}>See all</h2>
        </div>
        <CheckList
          data={data}
          val={val}
          setVal={setVal}
          current={current}
          singleVal={!!singleVal}
          displayFormat={displayFormat}
          submitAttr={submitAttr}
          obj={!!obj}
        />
      </div>
    </FilterContainerCSS>
  )
}

const FilterPageCSS = styled.div`
  .filter-page {
    .header {
      font-family: "DM Sans";
      width: 100vw;
      display: flex;
      align-items: center;
      background: #1d1d1d;
      padding: 1.125rem 1rem 1rem 1rem;
      box-sizing: border-box;

      img {
        width: 0.97375rem;
        height: auto;
      }

      h1,
      h2 {
        height: 1.375rem;
        font-style: normal;
        font-size: 1rem;
        line-height: 140%;
      }

      div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
      }

      h1 {
        margin-right: 0.97375rem;
        color: #f3f3f3;
        font-weight: 500;
      }
    }

    .body {
      height: 70vh;
      overflow-y: scroll;
    }

    .checklist-container {
      .checklist-item {
        padding: 1.125rem 1rem;
        border-bottom: 1px solid #373737;
        justify-content: space-between;

        .checkbox {
          order: 2;
          margin: 0;
        }

        h5 {
          order: 1;
        }
      }
    }
  }
`

MobileForm.FilterPage = ({
  title,
  backArrowAction,
  children,
  data,
  val,
  setVal,
  current,
  handleApplyFilter,
  singleVal,
  submitAttr,
  obj,
  displayFormat,
}) => {
  // const dispatch = useDispatch()
  return (
    <FilterPageCSS>
      <div className="filter-page">
        <div className="header">
          <img src={BackArrow} alt="" onClick={backArrowAction} />
          <div>
            <h1>{title}</h1>
          </div>
        </div>
        <div className="body">
          <CheckList
            data={data}
            val={val}
            setVal={setVal}
            current={current}
            singleVal={!!singleVal}
            displayFormat={displayFormat}
            submitAttr={submitAttr}
            obj={!!obj}
          />
        </div>
        <div className="search-submit" onClick={handleApplyFilter}>
          <Button>Apply filter</Button>
        </div>
      </div>
    </FilterPageCSS>
  )
}

MobileForm.FilterPageContainer = ({
  data,
  values,
  isSubmitting,
  setFieldValue,
  tempEmploymentType,
  setTempEmploymentType,
  tempIndustry,
  setTempIndustry,
  tempCompany,
  setTempCompany,
  isFiltered,
  submitForm,
  resetVal,
  employmentTypeObjArr,
  industryObjArr,
  locationObjArr,
  companyObjArr,
}) => {
  const dispatch = useDispatch()
  const [showFilterFullPage, setShowFilterFullPage] = useState(null)

  const {
    showMobileFilter,
    employmentType,
    company,
    industry,
    location,
  } = useSelector((state) => state.jobfair)

  const closeFilterPage = () => {
    setShowFilterFullPage(null)
  }

  const closeFilter = () => {
    dispatch(setShowMobileFilter(false))
  }

  const applyFilter = () => {
    submitForm()
    setShowFilterFullPage(null)
  }

  const defaultObjProps = {
    submitAttr: "attr",
    displayFormat: (obj) => `${obj.attr} (${obj.sum})`,
    obj: true,
  }

  return (
    <CSS>
      <Form
        className={`mobile-form-page-container ${
          showMobileFilter ? "show" : ""
        }`}
      >
        {
          {
            employmentType: (
              <MobileForm.FilterPage
                title="Employment Type"
                backArrowAction={closeFilterPage}
                data={employmentTypeObjArr}
                val={values.employmentType}
                setVal={(val) => setFieldValue("employmentType", val)}
                current={employmentType}
                handleApplyFilter={applyFilter}
                {...defaultObjProps}
              />
            ),
            company: (
              <MobileForm.FilterPage
                title="Company"
                backArrowAction={closeFilterPage}
                data={companyObjArr}
                val={values.company}
                setVal={(val) => setFieldValue("company", val)}
                current={company}
                handleApplyFilter={applyFilter}
                {...defaultObjProps}
              />
            ),
            industry: (
              <MobileForm.FilterPage
                title="Industry"
                backArrowAction={closeFilterPage}
                data={industryObjArr}
                val={values.industry}
                setVal={(val) => setFieldValue("industry", val)}
                current={industry}
                handleApplyFilter={applyFilter}
                {...defaultObjProps}
              />
            ),
            location: (
              <MobileForm.FilterPage
                title="Location"
                backArrowAction={closeFilterPage}
                data={locationObjArr}
                val={values.location}
                setVal={(val) => setFieldValue("location", val)}
                current={location}
                handleApplyFilter={applyFilter}
                singleVal
                {...defaultObjProps}
              />
            ),
            null: (
              <div className="mobile-filter">
                <div className="header">
                  <img src={CrossRound} alt="" onClick={closeFilter} />
                  <h1>Filter by</h1>
                  <h2
                    onClick={() => {
                      dispatch(clearFilter())
                      resetVal()
                    }}
                  >
                    Reset
                  </h2>
                </div>
                <div className="body">
                  <MobileForm.Filter
                    title="Employment Type"
                    seeAllAction={() => setShowFilterFullPage("employmentType")}
                    data={employmentTypeObjArr}
                    val={values.employmentType}
                    setVal={(val) => setFieldValue("employmentType", val)}
                    current={employmentType}
                    {...defaultObjProps}
                  />
                  <MobileForm.Filter
                    title="Company"
                    seeAllAction={() => setShowFilterFullPage("company")}
                    data={companyObjArr}
                    val={values.company}
                    setVal={(val) => setFieldValue("company", val)}
                    current={company}
                    {...defaultObjProps}
                  />
                  <MobileForm.Filter
                    title="Industry"
                    seeAllAction={() => setShowFilterFullPage("industry")}
                    data={industryObjArr}
                    val={values.industry}
                    setVal={(val) => setFieldValue("industry", val)}
                    current={industry}
                    {...defaultObjProps}
                  />
                  <MobileForm.Filter
                    title="Location"
                    seeAllAction={() => setShowFilterFullPage("location")}
                    data={locationObjArr}
                    val={values.location}
                    setVal={(val) => setFieldValue("location", val)}
                    current={location}
                    singleVal
                    {...defaultObjProps}
                  />
                </div>
                <div
                  className="search-submit"
                  onClick={() => {
                    submitForm()
                    closeFilter()
                  }}
                >
                  <Button>Apply filter</Button>
                </div>
              </div>
            ),
          }[showFilterFullPage]
        }
      </Form>
    </CSS>
  )
}

export default MobileForm
