import fuzzysearch from "fuzzysearch"

export const filterArr = (arr, compareTo) => {
  compareTo = cleanString(compareTo)
  return arr.filter((str) => {
    return compareStrIgnoreCase(str, compareTo)
      ? false
      : fuzzySearchStr(str, compareTo)
  })
}

export const filterObjArr = (arr, compareAttr, compareTo, includeExact) => {
  compareTo = cleanString(compareTo)
  return arr.filter((obj) => {
    const testedAttr = obj[compareAttr]
    return compareStrIgnoreCase(compareTo, testedAttr)
      ? !!includeExact
      : fuzzySearchStr(testedAttr, compareTo)
  })
}

export const sortObjArr = (arr, attr1, attr2) => {
  return arr.sort((obj1, obj2) => {
    if (obj1[attr1] === null) return -1
    if (obj2[attr1] === null) return 1
    const obj1Attr = attr2 ? obj1[attr1][attr2] : obj1[attr1]
    const obj2Attr = attr2 ? obj2[attr1][attr2] : obj2[attr1]
    return obj1Attr > obj2Attr ? 1 : obj1Attr < obj2Attr ? -1 : 0
  })
}

export const filterObjArrExact = (
  arr,
  compareTo,
  compareAttr1,
  compareAttr2
) => {
  return arr.filter((obj) => {
    const testedAttr =
      compareAttr2 && obj[compareAttr1]
        ? obj[compareAttr1][compareAttr2]
        : obj[compareAttr1]
    return compareStrIgnoreCase(cleanString(testedAttr), cleanString(compareTo))
  })
}

export const getObjArr = (uniqueVals, data, attr1, attr2) => {
  const res = []
  getUniqueStringArr(uniqueVals).map((val) => {
    const obj = {
      attr: val,
      sum: filterObjArrExact(data, val, attr1, attr2).length,
    }
    obj.sum > 0 && res.push(obj)
  })
  return res
}

export const getUniqueStringArr = (arr) => {
  const res = []
  arr.map((str) => !res.includes(str.trim()) && res.push(str.trim()))
  return res
}

export const matchStringByWord = (str1, str2) => {
  let haystack
  let needle
  str1 = str1.toLocaleLowerCase()
  str2 = str2.toLocaleLowerCase()

  if (str1.length >= str2.length) {
    haystack = str1.split(" ")
    needle = str2.split(" ")
  } else {
    haystack = str2.split(" ")
    needle = str1.split(" ")
  }
  for (let i = 0; i < needle.length; i++) {
    if (haystack.includes(needle[i])) {
      return true
    }
  }
  return false
}

export const fuzzySearchStr = (str1, str2) => {
  try {
    str1 = str1.toLocaleLowerCase()
    str2 = str2.toLocaleLowerCase()
    return str1.length >= str2.length
      ? fuzzysearch(str2, str1) ||
          str1.includes(str2) ||
          matchStringByWord(str1, str2)
      : fuzzysearch(str1, str2) ||
          str2.includes(str1) ||
          matchStringByWord(str1, str2)
  } catch (err) {
    return false
  }
}

export const compareStrIgnoreCase = (a, b) =>
  a.toLocaleLowerCase() === b.toLocaleLowerCase()

export const findInArr = (str, arr) => {
  let res = false
  arr.map((arrItem) => {
    if (fuzzySearchStr(str, arrItem)) {
      res = true
    }
  })
  return res
}

export const handleVarErr = (val) => {
  try {
    return val
  } catch (err) {
    return ""
  }
}

export const cleanString = (str) => {
  if (str !== undefined) {
    return str.replace(/[&/\\#,+()$~%.'":*?<>{}]/g, "").trim()
  } else {
    return ""
  }
}

export const handleNullErrorString = (val) => {
  try {
    return val
  } catch (err) {
    return ""
  }
}
